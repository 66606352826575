<template>
    <main class="flex-1 overflow-y-auto">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
            <h1 id="primary-heading" class="sr-only">Profil</h1>
            <div class="p-6 h-full">
                <div class="block border-2 border-dashed border-gray-300 rounded h-full w-full text-gray-200"></div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: 'Profile',
};
</script>
